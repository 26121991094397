export function userNewsApi() {
  const apiVersion = useRuntimeConfig().API_VER_1
  return {
    CATEGORIES: `${apiVersion}/brand/category?alias=tin-tuc`,
    POST_DETAIL: `${apiVersion}/brand/news`,
    POST_FEATURES: `${apiVersion}/posts/feature`,
    NEW_POSTS: `${apiVersion}/brand/news`,
    CATEGORIES_POSTS: `${apiVersion}/categories/posts`
  }
}
